export default defineNuxtRouteMiddleware(async to => {
  const client = useSupabaseClient();

  console.log('to', to);

  try {
    const {
      data: {session},
      error
    } = await client.auth.getSession();

    if (error?.message.includes('Invalid Refresh Token') || !session) {
      // Pause one second
      await new Promise(resolve => setTimeout(resolve, 5000));
      // Clear local storage and redirect to login
      await client.auth.signOut();
      return navigateTo('/app/login');
    }
  } catch (error) {
    if (error.message.includes('Invalid Refresh Token')) {
      // Pause one second
      await new Promise(resolve => setTimeout(resolve, 5000));
      await client.auth.signOut();
      return navigateTo('/app/login');
    }
  }
});
